import { Pipe, PipeTransform } from '@angular/core';
import { CompetitionAssignee } from '@ssmm-shared/data/models/competition/competition-assignee.interface';
import dayjs from 'dayjs';

@Pipe({
  name: 'meldeschlussDisplayText'
})
export class MeldeschlussDisplayTextPipe implements PipeTransform {
  transform(value: CompetitionAssignee): string {
    if (!value || !value?.meldeschluss) {
      return '';
    }
    const meldeschluss = dayjs(value.meldeschluss);
    if (meldeschluss.isAfter(dayjs(new Date()))) {
      return `Meldeschluss: ${meldeschluss.format('DD.MM.YYYY HH:mm')}`;
    }
    return `Meldeschluss war: ${meldeschluss.format('DD.MM.YYYY HH:mm')}`;
  }
}
