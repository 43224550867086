import { Injectable } from '@angular/core';
import { Saison } from '../../services/data/saison.interface';

@Injectable({
  providedIn: 'root'
})
export class HalService {
  readonly halKeyNutzerliste = 'mm_nutzer_verzeichnis';
  readonly halKeyNutzerInstitutionen = 'mm_nutzer_institutionen';
  readonly halKeyNutzerLandesbeauftragte = 'mm_nutzer_landesbeauftragte';
  readonly halKeySeasonCreate = 'mm_saison_erstellen';
  readonly halKeySeasonEdit = 'mm_saison_bearbeiten';
  readonly halKeySeasonFinals = 'mm_saison_finaltermine';
  readonly halKeyWettbewerbWkk = 'mm_wettbewerb_wkk';
  readonly halKeyWettbewerbSchulart = 'mm_wettbewerb_sa';
  readonly halKeySuffixAvailable = '_verfuegbar';
  readonly halKeyWettbewerbSportarten = 'mm_wettbewerb_sa';
  readonly halKeyWettbewerbSportartenVerfuegbar =
    'mm_wettbewerb_spa_verfuegbar';
  readonly halKeyWettbewerbFinale = 'mm_wettbewerb_finale';
  readonly halKeyWettbewerbListe = 'mm_saison_wettkaempfe';
  readonly halKeyWettbewerbFreigabe = 'mm_wettkaempfe_set_freigegeben';
  readonly halKeySportartFinale = 'mm_sportart_finale';
  readonly halKeySportartZuordenbar = 'mm_sportart_zuordenbar';
  readonly halkeySportartZuweisung = 'mm_wettbewerb_spa';
  readonly halKeySportartPiktogramm = 'mm_sportart_piktogram';
  readonly halKeyFreigabeSaisonRED = 'mm_saison_freigeben_red';
  readonly halKeyFreigabeSaisonAlle = 'mm_saison_freigeben_alle';
  readonly halKeySportartBeauftragte = 'mm_nutzer_vw_rp_beauftragte';
  readonly halKeySportartBeauftragteBeaKr = 'mm_bea_kr_spa';
  readonly halKeySportartBeauftragteBeaRP = 'mm_bea_rp_spa';

  readonly halKeySchuleOrte = 'mm_nutzer_orte';
  readonly halKeySchuleliste = 'mm_nutzer_schulen';
  readonly halKeySchularten = 'mm_nutzer_schularten';
  readonly halKeyNutzerBeauftragungen = 'mm_nutzer_beauftragungen';
  readonly halKeyNutzerMeldeberechtigungen = 'mm_nutzer_meldeberechtigungen';
  readonly halKeyWkBeauftragungGeo = 'mm_wk_beauftragung_geo';

  readonly halKeyBeaRundmails = 'mm_bea_rundmails';
  readonly halKeyGemeldeteManschaftenBea = 'mm_wk_bea_mannschaften';
  readonly halKeyBeaMyFixtures = 'mm_bea_veranstaltungen';
  readonly halKeyVlMyFixtures = 'mm_vl_veranstaltungen';
  readonly halKeyVlMyTeams = 'mm_vl_mannschaften';

  pageTitle: string;
  halNavLinks: string[];

  private _viewedSaison: Saison;

  set viewedSaison(value: Saison) {
    this._viewedSaison = value;
  }

  getSaisonLink(): string {
    return this._viewedSaison
      ? this._viewedSaison._links.mm_navigation.href
      : 'machmit-server/rest/v1';
  }

  getSaisonName(): string {
    return this._viewedSaison?.displayName ?? '';
  }

  getSaisonYears(): string {
    return this._viewedSaison?.displayName?.substring(7) ?? '';
  }

  getSaisonStartYear(): number {
    // DisplayName is in Format "Saison 20XX/YY Alias"
    if (!this._viewedSaison || this._viewedSaison.displayName.length < 11) {
      return 0;
    }

    return Number(this._viewedSaison.displayName.slice(7, 11));
  }
}
