import { Pipe, PipeTransform } from '@angular/core';
import { HalService } from '@ssmm-shared/services/hal.service';

@Pipe({
  name: 'extendWithSeason'
})
export class ExtendWithSeasonPipe implements PipeTransform {
  constructor(private _halService: HalService) {}

  transform(affix: string, suffix = ''): string {
    return `${affix} ${this._halService.getSaisonYears()} ${suffix}`;
  }
}
