<!-- Assign Kreise Dialog -->
<kendo-dialog
  title="{{ title }}"
  *ngIf="isDialogOpen"
  (close)="close()"
  [minWidth]="250"
  [width]="650"
>
  <kendo-grid
    scrollable="virtual"
    class="light-italic-table"
    [rowClass]="rowCallback"
    [data]="gridData"
    [pageable]="false"
    [selectable]="selectableSettings"
    [kendoGridSelectBy]="'geoElement'"
    [(selectedKeys)]="selectedCompetitions"
  >
    <kendo-grid-messages
      noRecords="Keine Wettkämpfe verfügbar."
    ></kendo-grid-messages>
    <kendo-grid-checkbox-column
      showSelectAll="false"
      [width]="50"
      #selectAllColumn
    >
      <ng-template kendoGridHeaderTemplate>
        <input
          type="checkbox"
          kendoCheckBox
          id="cbxSelectAllHeader"
          [checked]="
            gridData?.length | equalNumber : selectedCompetitions.length
          "
          #ctlCheckAll
          (change)="toggleSelectAll($event)"
        />
        <label class="k-checkbox-label" for="cbxSelectAllHeader"></label>
      </ng-template>
      <ng-template kendoGridCellTemplate let-idx="rowIndex" let-dataItem>
        <input
          type="checkbox"
          kendoCheckBox
          [id]="'cbx' + idx"
          [checked]="getIsChecked(dataItem.wettkampf.id)"
          (change)="setCheckStatus(dataItem.wettkampf)"
          [disabled]="getIsDisabled(idx)"
        />
        <label class="k-checkbox-label" [for]="'cbx' + idx"></label>
      </ng-template>
    </kendo-grid-checkbox-column>
    <kendo-grid-column field="wettkampf.name" [title]="getColumnTitle()">
    </kendo-grid-column>
    <kendo-grid-column *ngIf="showMeldeschlussColumn" [title]="'Meldeschluss'">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.meldeschluss | date : 'dd.MM.yyyy HH:mm' }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column *ngIf="showSchulartenColumn" [title]="'Schularten'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ dataItem.schularten | join : ', ' }}</span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>

  <kendo-dialog-actions [layout]="'stretched'">
    <button type="button" kendoButton (click)="close()">Abbrechen</button>
    <button
      type="button"
      kendoButton
      themeColor="primary"
      [disabled]="!gridData || !gridData.length"
      (click)="save()"
    >
      Speichern
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
