<kendo-dialog
  class="kendo-medium-dialog"
  [title]="title"
  *ngIf="isDialogOpen && categoriesFiltered?.length > 0"
  (close)="close()"
>
  <!-- Body -->
  <kendo-tabstrip (tabSelect)="changeCategory($event.title)">
    <kendo-tabstrip-tab
      *ngFor="let category of categoriesFiltered; let i = index"
      [title]="category.title"
      [selected]="i === selectedTabIndex"
      [disabled]="i === selectedTabIndex"
    >
      <ng-template kendoTabContent>
        <!-- Upload control -->
        <kendo-fileselect
          *ngIf="canUserUploadDocs && selectedTabIndex === 0"
          (select)="docToUploadSelected($event)"
          [multiple]="true"
          [showFileList]="false"
          [disabled]="docs?.length >= 5"
          [restrictions]="uploadRestrictions"
        >
          Upload
        </kendo-fileselect>

        <!-- List -->
        <div>
          <kendo-grid [data]="docs" class="kendo-grid-collapsed-header">
            <kendo-grid-messages
              noRecords="Keine Dokumente vorhanden."
            ></kendo-grid-messages>
            <!-- Document name -->
            <kendo-grid-column title="">
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.name }}
              </ng-template>
            </kendo-grid-column>
            <!-- Download / Open -->
            <kendo-grid-column title="" [width]="100">
              <ng-template kendoGridCellTemplate let-dataItem>
                <ng-container *ngIf="dataItem.item | async as item">
                  <!-- Loading spinner -->
                  <div
                    class="k-i-loading"
                    *ngIf="
                      !item._links ||
                      downloadingFileNames[dataItem.name.normalize('NFC')] ||
                      deletingFileNames[dataItem.name.normalize('NFC')]
                    "
                  ></div>
                  <!-- Download doc button -->
                  <div
                    class="ml-auto"
                    *ngIf="
                      item._links?.mm_dokument_content &&
                      !downloadingFileNames[dataItem.name.normalize('NFC')] &&
                      !deletingFileNames[dataItem.name.normalize('NFC')]
                    "
                  >
                    <!-- Hidden anchor used for actual download -->
                    <a
                      class="anchor-download-doc"
                      [id]="'doc-' + dataItem.name"
                      [href]="downloadedFiles[dataItem.name]?.safeUrl"
                    ></a>
                    <!-- Download trigger -->
                    <button
                      class="awesome-btn-default"
                      themeColor="primary"
                      kendoButton
                      (click)="downloadDoc(dataItem)"
                    >
                      <fa-icon [icon]="['fas', 'download']"></fa-icon>
                    </button></div></ng-container
              ></ng-template>
            </kendo-grid-column>

            <!-- Delete / Cancel upload -->
            <kendo-grid-column [width]="100" *ngIf="canUserUploadDocs">
              <ng-template kendoGridCellTemplate let-dataItem
                ><div class="ml-auto column-delete">
                  <button
                    *ngIf="!deletingFileNames[dataItem.name]"
                    #btnDeleteCancelDoc
                    class="awesome-btn-red"
                    themeColor="primary"
                    kendoButton
                    (click)="deleteDocCancelUploadRequested(dataItem)"
                  >
                    <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
                  </button></div></ng-template
            ></kendo-grid-column>
          </kendo-grid>
          <div
            class="mr-3 mt-3 lbl-max-files-amount"
            *ngIf="canUserUploadDocs && selectedTabIndex === 0"
          >
            max. 5 Dateien und 10MB pro Datei
          </div>
          <div
            class="mr-2 mt-3 lbl-max-files-amount"
            *ngIf="canUserUploadDocs && selectedTabIndex === 0"
          >
            Erlaubte Zeichen in Dateinamen: a-z A-Z 0-9 ä ö ü Ä Ö Ü ß . - _
            Leerzeichen
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>

  <!-- Dialog Actions -->
  <kendo-dialog-actions [layout]="'stretched'">
    <button type="button" kendoButton (click)="close()">Schließen</button>
  </kendo-dialog-actions>
</kendo-dialog>

<!-- Delete doc dialog -->
<ssmm-yes-no-dialog
  #deleteDocCancelUploadDialog
  [title]="documentToDelete | toDeleteDocCancelUploadDialogTitle"
  (aborted)="resetDeleteDocCancelUpload()"
  (confirmed)="deleteDocCancelUpload()"
></ssmm-yes-no-dialog>
