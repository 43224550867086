<div class="modal-size">
  <kendo-dialog *ngIf="opened" (close)="onDialogClose()">
    <kendo-dialog-titlebar>
      <span>{{ title }}</span>
    </kendo-dialog-titlebar>
    <div>
      <div class="row mb-3">
        <label class="col-md-5 col-form-label" [for]="firstName"
          >* Vorname</label
        >
        <div class="col-md-7">
          <input
            kendoTextBox
            class="form-control"
            #firstName
            [formControl]="formModel.firstName"
          />
          <ssmm-errors
            [isValidationActive]="wasSaveRequested"
            [control]="formModel.firstName"
          ></ssmm-errors>
        </div>
      </div>
      <div class="row mb-3">
        <label class="col-md-5 col-form-label" [for]="lastName">* Name</label>
        <div class="col-md-7">
          <input
            kendoTextBox
            class="form-control"
            #lastName
            [formControl]="formModel.lastName"
          />
          <ssmm-errors
            [isValidationActive]="wasSaveRequested"
            [control]="formModel.lastName"
          ></ssmm-errors>
        </div>
      </div>
      <div class="row mb-3">
        <label class="col-md-5 col-form-label" [for]="ageGroup"
          >* Jahrgang</label
        >
        <div class="col-md-7">
          <kendo-dropdownlist
            #ageGroup
            class="form-control"
            [data]="ageGroupValues"
            [textField]="'text'"
            [valueField]="'value'"
            [defaultItem]="dropDownDefaultItem"
            [formControl]="formModel.ageGroup"
          ></kendo-dropdownlist>
          <ssmm-errors
            [control]="formModel.ageGroup"
            [isValidationActive]="wasSaveRequested"
          ></ssmm-errors>
        </div>
      </div>
      <div class="row mb-3">
        <label class="col-md-5 col-form-label">* Geschlecht</label>
        <div class="col-md-7">
          <kendo-dropdownlist
            #ageGroup
            class="form-control"
            [data]="sexValues"
            [textField]="'text'"
            [valueField]="'value'"
            [defaultItem]="dropDownDefaultItem"
            [formControl]="formModel.sex"
          ></kendo-dropdownlist>
          <ssmm-errors
            [control]="formModel.sex"
            [isValidationActive]="wasSaveRequested"
          ></ssmm-errors>
        </div>
      </div>
      <div class="row mb-3" *ngIf="!repeatedStudent">
        <div class="col-1 m-auto">
          <input type="checkbox" [formControl]="formModel.termsAndConditions" />
        </div>
        <div class="col-11">
          <label class="col-form-label"
            >* Schriftliche Einwilligung zur Datenverarbeitung liegt mir
            vor.</label
          >
        </div>
        <ssmm-errors
          [isValidationActive]="wasSaveRequested"
          [control]="formModel.termsAndConditions"
        ></ssmm-errors>
      </div>
      <div *ngIf="repeatedStudent">
        <label class="col-12">
          Wollen Sie wirklich einen weiteren Schüler mit diesen Daten anlegen?
        </label>
      </div>
    </div>

    <kendo-dialog-actions [layout]="'stretched'">
      <button kendoButton (click)="onDialogClose()">Abbrechen</button>
      <button
        *ngIf="editMode && repeatedStudent"
        kendoButton
        [disabled]="wrapperGroup.invalid"
        (click)="onAddStudent()"
        themeColor="primary"
      >
        Trotzdem anlegen
      </button>
      <button
        *ngIf="!editMode"
        kendoButton
        [disabled]="wrapperGroup.invalid"
        (click)="onAddStudent()"
        themeColor="primary"
      >
        Speichern
      </button>
      <button
        *ngIf="editMode && !repeatedStudent"
        kendoButton
        [disabled]="wrapperGroup.invalid"
        (click)="onEditStudent()"
        themeColor="primary"
      >
        Speichern
      </button>
    </kendo-dialog-actions>
  </kendo-dialog>
</div>
