import { NotificationModule } from '@progress/kendo-angular-notification';
import { MeldeleistungControlsPipe } from './views/meldeleistungen/pipes/meldeleistungen-controls.pipe';
import { DataFormatMaskPipe } from './pipes/data-format-mask.pipe';
import { ReversePipe } from './pipes/reverse.pipe';
import { AgeGroupService } from './services/age-group.service';
import { UploadDocsDialogComponent } from './dialogs/upload-docs-dialog/upload-docs-dialog.component';
import { AwesomeIconsModule } from './../awesome-icons/awesome-icons.module';
import { YesNoDialogComponent } from '@ssmm-shared/dialogs/yes-no-dialog/yes-no-dialog.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// Commonly used Kendo UI controls
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogModule } from '@progress/kendo-angular-dialog';
import {
  DropDownListComponent,
  DropDownListModule,
  DropDownsModule
} from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { BottomNavComponent } from './bottom-nav/bottom-nav.component';
import { ErrorsComponent } from './errors/errors.component';
import { BackendService } from './services/backend.service';
import { BackendServiceMock } from './services/backend.service.mock';
import { LogLevel } from './services/log/log-level.enum';
import { LOGGER_LEVEL } from './services/log/log-level.token';
import { TreeviewComponent } from './treeview/treeview.component';
import { MapPipe } from './pipes/map.pipe';
import { JoinPipe } from './pipes/join.pipe';
import { SchoolListWithSearchComponent } from './school-list-with-search/school-list-with-search.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SchoolAssignmentDialogComponent } from './dialogs/school-assignment-dialog/school-assignment-dialog.component';
import { AgeGroupPipe } from '@ssmm-shared/pipes/age-group.pipe';
import { MaxMinPipe } from '@ssmm-shared/pipes/max-min.pipe';
import { SaveCancelDialogComponent } from '@ssmm-shared/views/save-cancel-dialog/save-cancel-dialog.component';
import { CategoryTabsComponent } from '@ssmm-shared/category-tabs/category-tabs.component';
import { UserListComponent } from './views/user-list/user-list.component';
import { UserSelectionDialogComponent } from './dialogs/user-selection-dialog/user-selection-dialog.component';
import { MyCompetitionsDialogComponent } from './dialogs/my-competitions-dialog/my-competitions-dialog.component';
import { UserSelectionToggleComponent } from './views/user-selection-toggle/user-selection-toggle.component';
import { BeaAssignmentDialogComponent } from './dialogs/bea-assignment-dialog/bea-assignment-dialog.component';
import { GeoNamesSeparatedPipe } from './dialogs/bea-assignment-dialog/pipes/geo-names-separated.pipe';
import { ToUserPipe } from './pipes/to-user.pipe';
import { UserFullNamePipe } from './pipes/user-full-name.pipe';
import { FixtureRoundPipe } from './pipes/fixture-round.pipe';
import { FixtureSummaryComponent } from './views/fixture-summary/fixture-summary.component';
import { PictogramPipe } from './pipes/pictogram.pipe';
import { UserDetailsDialogComponent } from './dialogs/user-details-dialog/user-details-dialog.component';
import { PictogramComponent } from './views/pictogram/pictogram.component';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { NgxStateStackModule } from 'ngx-state-stack';
import { SchoolTypesShortPipe } from './pipes/school-types-short.pipe';
import { AgeGroupBackPipe } from './pipes/age-group-back.pipe';
import { ValueKeyListComponent } from './views/value-key-list/value-key-list.component';
import { SexPipe } from './pipes/sex.pipe';
import { DataFormatPromptPipe } from './pipes/data-format-prompt.pipe';
import { MeldeleistungenComponent } from './views/meldeleistungen/meldeleistungen.component';
import { DynamicAddonComponent } from './views/dynamic-addon/dynamic-addon.component';
import { SchoolDetailsDialogComponent } from './dialogs/school-details-dialog/school-details-dialog.component';
import { AmountPropertiesPipe } from './pipes/amount-properties.pipe';
import { BtnUploadComponent } from './btn-upload/btn-upload.component';
import { AreAllItemsSelectedPipe } from './pipes/are-all-items-selected.pipe';
import { TitleWithSeasonPipe } from './pipes/title-with-season.pipe';
import { GeoElementeDefaultItemPipe } from './pipes/geo-elemente-default-item.pipe';
import { GridHeaderSecondRowComponent } from './views/grid-header-second-row/grid-header-second-row.component';
import { KendoGridSearchComponent } from './kendo-grid/kendo-grid-search/kendo-grid-search.component';
import { VlAssignmentDialogComponent } from './dialogs/vl-assignment-dialog/vl-assignment-dialog.component';
import { ErrorDialogComponent } from './dialogs/error-dialog/error-dialog.component';
import { EqualNumberPipe } from './pipes/equal-number.pipe';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { IconTooltipComponent } from './icon-tooltip/icon-tooltip.component';
import { UserCreationFormComponent } from '@ssmm-shared/views/user-creation-form/user-creation-form.component';
import { UserCreationAndAssignmentDialogComponent } from '@ssmm-shared/views/user-creation-and-assignment-dialog/user-creation-and-assignment-dialog.component';
import { ToDeleteDocCancelUploadDialogTitlePipe } from './dialogs/upload-docs-dialog/pipes/to-delete-doc-cancel-upload-dialog-title.pipe';
import { MaskedTextboxFormValuePipe } from './pipes/masked-textbox-form-value.pipe';
import { ToKendoDropdownTreeItemPipe } from './pipes/to-kendo-dropdown-tree-item.pipe';
import { MeldeschlussDisplayTextPipe } from './dialogs/bea-assignment-dialog/pipes/meldeschluss-display-text.pipe';
import { GetItemIndexPipe } from '@ssmm-shared/pipes/get-item-index.pipe';
import { ExtendWithSeasonPipe } from './pipes/extend-with-season.pipe';
import { ToDropdownItemsPipe } from './pipes/to-dropdown-items.pipe';
import { CreateEditStudentDialogComponent } from './dialogs/create-student-dialog/create-edit-student.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DropDownListModule,
    DropDownsModule,
    DateInputsModule,
    TextBoxModule,
    GridModule,
    ButtonsModule,
    LayoutModule,
    DialogModule,
    LabelModule,
    HttpClientModule,
    InputsModule,
    ReactiveFormsModule,
    AwesomeIconsModule,
    UploadsModule,
    NgxStateStackModule,
    TooltipModule,
    NotificationModule
  ],
  declarations: [
    TreeviewComponent,
    UserListComponent,
    ErrorsComponent,
    BottomNavComponent,
    MapPipe,
    JoinPipe,
    SaveCancelDialogComponent,
    SchoolListWithSearchComponent,
    SchoolAssignmentDialogComponent,
    YesNoDialogComponent,
    AgeGroupPipe,
    MaxMinPipe,
    SaveCancelDialogComponent,
    CategoryTabsComponent,
    UserSelectionDialogComponent,
    MyCompetitionsDialogComponent,
    UserSelectionToggleComponent,
    BeaAssignmentDialogComponent,
    GeoNamesSeparatedPipe,
    ToUserPipe,
    UserFullNamePipe,
    FixtureRoundPipe,
    FixtureSummaryComponent,
    PictogramPipe,
    UserDetailsDialogComponent,
    PictogramComponent,
    SchoolTypesShortPipe,
    UploadDocsDialogComponent,
    AgeGroupBackPipe,
    ValueKeyListComponent,
    SexPipe,
    ReversePipe,
    DataFormatMaskPipe,
    DataFormatPromptPipe,
    MaskedTextboxFormValuePipe,
    MeldeleistungenComponent,
    MeldeleistungControlsPipe,
    DynamicAddonComponent,
    SchoolDetailsDialogComponent,
    AmountPropertiesPipe,
    BtnUploadComponent,
    AreAllItemsSelectedPipe,
    TitleWithSeasonPipe,
    GeoElementeDefaultItemPipe,
    GridHeaderSecondRowComponent,
    KendoGridSearchComponent,
    VlAssignmentDialogComponent,
    ErrorDialogComponent,
    EqualNumberPipe,
    IconTooltipComponent,
    UserCreationFormComponent,
    UserCreationAndAssignmentDialogComponent,
    ToDeleteDocCancelUploadDialogTitlePipe,
    ToKendoDropdownTreeItemPipe,
    MeldeschlussDisplayTextPipe,
    GetItemIndexPipe,
    ExtendWithSeasonPipe,
    ToDropdownItemsPipe,
    CreateEditStudentDialogComponent
  ],
  exports: [
    ReactiveFormsModule,
    UserListComponent,
    TreeviewComponent,
    DropDownListComponent,
    DropDownsModule,
    TextBoxModule,
    GridModule,
    ButtonsModule,
    DialogModule,
    LayoutModule,
    DateInputsModule,
    ErrorsComponent,
    LabelModule,
    BottomNavComponent,
    InputsModule,
    NotificationModule,
    MapPipe,
    JoinPipe,
    SaveCancelDialogComponent,
    SchoolListWithSearchComponent,
    SchoolAssignmentDialogComponent,
    YesNoDialogComponent,
    AgeGroupPipe,
    MaxMinPipe,
    SaveCancelDialogComponent,
    AwesomeIconsModule,
    CategoryTabsComponent,
    UserSelectionDialogComponent,
    MyCompetitionsDialogComponent,
    UserSelectionToggleComponent,
    BeaAssignmentDialogComponent,
    ToUserPipe,
    UserFullNamePipe,
    FixtureRoundPipe,
    FixtureSummaryComponent,
    PictogramPipe,
    UserDetailsDialogComponent,
    PictogramComponent,
    SchoolTypesShortPipe,
    UploadDocsDialogComponent,
    UploadsModule,
    AgeGroupBackPipe,
    ValueKeyListComponent,
    SexPipe,
    ReversePipe,
    DataFormatMaskPipe,
    DataFormatPromptPipe,
    MaskedTextboxFormValuePipe,
    MeldeleistungenComponent,
    DynamicAddonComponent,
    SchoolDetailsDialogComponent,
    AmountPropertiesPipe,
    BtnUploadComponent,
    AreAllItemsSelectedPipe,
    TitleWithSeasonPipe,
    GridHeaderSecondRowComponent,
    KendoGridSearchComponent,
    VlAssignmentDialogComponent,
    ErrorDialogComponent,
    EqualNumberPipe,
    IconTooltipComponent,
    ToDeleteDocCancelUploadDialogTitlePipe,
    ToKendoDropdownTreeItemPipe,
    GetItemIndexPipe,
    ExtendWithSeasonPipe,
    ToDropdownItemsPipe,
    CreateEditStudentDialogComponent
  ]
})
export class SharedModule {
  static forRoot(logLevel: LogLevel): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: LOGGER_LEVEL, useValue: logLevel },
        BackendService,
        AgeGroupService,
        AgeGroupPipe,
        AgeGroupBackPipe,
        AmountPropertiesPipe
      ]
    };
  }

  static forMockRoot(logLevel: LogLevel): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: LOGGER_LEVEL, useValue: logLevel },
        { provide: BackendService, useClass: BackendServiceMock },
        AgeGroupService,
        AgeGroupPipe,
        AgeGroupBackPipe,
        AmountPropertiesPipe
      ]
    };
  }
}
