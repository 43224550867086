import { ValidationErrors, AbstractControl } from '@angular/forms';
import { KendoDropdownItem } from '@ssmm-shared/data/kendo-dropdown-item.interface';
import { WettkampfModus } from 'ssmm-models';

export class DropDownValidators {
  static townWithPlzNotDefaultSelected(ctl: AbstractControl): ValidationErrors {
    if (!ctl.value?.value) {
      return { requiredDropdown: true };
    }

    return null;
  }

  static notDefaultItemSelected(ctl: AbstractControl): ValidationErrors {
    const kendoDropdownItem = <KendoDropdownItem>ctl.value;
    if (!kendoDropdownItem || kendoDropdownItem.value < 0) {
      return { requiredDropdown: true };
    }

    return null;
  }

  static notDefaultWettkampfModus(ctl: AbstractControl): ValidationErrors {
    const kendoDropdownItem = <WettkampfModus>ctl.value;
    if (!kendoDropdownItem || kendoDropdownItem.wkMode === null) {
      return { requiredDropdown: true };
    }

    return null;
  }
}
