export enum DocumentType {
  VERANSTALTUNGSINFORMATIONEN = 'Info zur Veranstaltung',
  BETREUERINFORMATIONEN = 'Betreuerinfo',
  ERGEBNISSE = 'Mannschaftsergebnisse'
}

export function getDocumentTypeKeyByIndex(index: number): string | undefined {
  const keys = Object.keys(DocumentType);
  return keys[index];
}

export default DocumentType;
