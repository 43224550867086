import { Pipe, PipeTransform } from '@angular/core';
import { DropdownItem } from '@ssmm-shared/interfaces/dropdown-item.interface';

@Pipe({
  name: 'toDropdownItems'
})
export class ToDropdownItemsPipe<T> implements PipeTransform {
  /**
   * Transforms an input array of type `T` into an array of `DropdownItem<T>`.
   *
   * @param input The input array of type `T` to be transformed.
   * @param defaultItem An optional `DropdownItem<T>` to be added at the beginning of the output array.
   * @param displayValueFunc An optional function that takes an element of type `T` and returns a string.
   *                         This string is used as the `text` property of the resulting `DropdownItem`.
   *                         If not provided, `toString()` method of the element is used.
   * @returns An array of `DropdownItem<T>` representing the transformed input array.
   */
  transform(
    input: T[],
    defaultItem?: DropdownItem<T>,
    displayValueFunc?: (val: T) => string
  ): DropdownItem<T>[] {
    if (input === undefined || input.length === 0) {
      return !!defaultItem ? [defaultItem] : [];
    }

    const res = input.map(val => ({
      text: !!displayValueFunc ? displayValueFunc(val) : val.toString(),
      value: val
    }));

    if (!!defaultItem) {
      res.unshift(defaultItem);
    }

    return res;
  }
}
