import { Pipe, PipeTransform } from '@angular/core';
import { isEqual } from 'lodash';

/**
 * Pipe to find the index of an item in an array. The Transform function transforms the input item and array
 * to find the index of the item in the array. If the <b>item is not found</b> in the array or either the
 * item or the array is not present, the function <b>returns -1</b>.<br><br>
 * If no custom comparator function is provided, the <b>default comparison will use lodash's isEqual function</b>.
 */
@Pipe({
  name: 'getItemIndex'
})
export class GetItemIndexPipe<T> implements PipeTransform {
  /**
   * Transforms the input item and array to find the index of the item in the array.
   * If no custom comparator function is provided, the comparison is done using lodash's isEqual function.
   *
   * @param item - The item to find in the array.
   * @param array - The array in which to search for the item.
   * @param comparatorFunc - Optional custom comparator function to determine equality.
   * @returns The index of the item in the array, or -1 if not found.
   */
  transform(item: T, array: T[], comparatorFunc?: Function): number {
    if (!item || !array || !array.length) {
      return -1;
    }
    if (!!comparatorFunc) {
      return array.findIndex(x => comparatorFunc(x, item));
    }
    return array.findIndex(x => isEqual(x, item));
  }
}
