import { WettkaempfeSelectableSaveRequest } from './../../../my-data/bea-assignments/data/wettkaempfe-selectable-save-request.interface';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output
} from '@angular/core';
import {
  RowArgs,
  RowClassArgs,
  SelectableSettings
} from '@progress/kendo-angular-grid';
import * as _ from 'lodash';
import { CompetitionSelectable } from '@ssmm-shared/data/models/competition/competition-selectable.interface';
import { GridCheckboxSelectionService } from '@ssmm-shared/services/grid-checkbox-selection/grid-checkbox-selection.service';
import { Competition } from '@ssmm-shared/data/models/competition/competition.interface';
import { RowClass } from '@ssmm-shared/data/row-class.interface';

@Component({
  selector: 'ssmm-my-competitions-dialog',
  templateUrl: './my-competitions-dialog.component.html'
})
export class MyCompetitionsDialogComponent {
  @Output()
  saveRequested = new EventEmitter<WettkaempfeSelectableSaveRequest>();

  title: string;
  isDialogOpen: boolean;
  competitionName: string;
  districtName: string;

  gridData: CompetitionSelectable[] = [];
  selectedCompetitions: Competition[] = [];
  showMeldeschlussColumn: boolean;
  showSchulartenColumn: boolean;

  selectableSettings: SelectableSettings = {
    mode: 'multiple',
    checkboxOnly: true
  };
  private _saveUri: string;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _gridCheckboxSelectionService: GridCheckboxSelectionService
  ) {}

  select(arg: RowArgs): any {
    return arg.dataItem;
  }

  open(
    pageTitle: string,
    saveUri: string,
    allComps: CompetitionSelectable[],
    competitionName: string,
    districtName: string,
    showMeldeschlussColumn = false,
    showSchulartenColumn = false
  ): void {
    this.showMeldeschlussColumn = showMeldeschlussColumn;
    this.showSchulartenColumn = showSchulartenColumn;
    this.title = pageTitle;
    this.competitionName = competitionName;
    this.districtName = districtName;
    this._saveUri = saveUri;

    this.gridData = _.cloneDeep(allComps);

    // Reselect previously selected
    const selectedGeoElements = allComps.filter(c => c.selektiert);
    if (selectedGeoElements.length) {
      this.selectedCompetitions = selectedGeoElements.map(s => s.wettkampf);
    } else {
      this.selectedCompetitions = [];
    }

    this.isDialogOpen = true;
    this._cdr.markForCheck();
  }

  close(): void {
    this.isDialogOpen = false;
  }

  save(): void {
    const itemsToSelect: CompetitionSelectable[] = _.intersectionWith(
      this.gridData,
      this.selectedCompetitions,
      (a, b) => a.wettkampf.id === b.id
    );
    itemsToSelect.forEach(i => (i.selektiert = true));

    const itemsToDeselect: CompetitionSelectable[] = _.xor(
      this.gridData,
      itemsToSelect
    );
    itemsToDeselect.forEach(i => (i.selektiert = false));

    this.saveRequested.emit(<WettkaempfeSelectableSaveRequest>{
      saveUri: this._saveUri,
      wettkaempfeSelectable: [...itemsToSelect, ...itemsToDeselect]
    });

    this.close();
  }

  getIsChecked(id: number): boolean {
    if (!this.selectedCompetitions) {
      return false;
    }

    return this.selectedCompetitions.find(d => d.id === id) !== void 0;
  }

  getColumnTitle(): string {
    let title = this.competitionName;

    if (this.districtName) {
      title += ` — ${this.districtName}`;
    }

    return title;
  }

  toggleSelectAll(event: Event): void {
    const isSelect: boolean = (<HTMLInputElement>event.target).checked;

    this.selectedCompetitions = this._gridCheckboxSelectionService
      .getSelectedItems<CompetitionSelectable>(isSelect, this.gridData)
      .map(d => d.wettkampf);
  }

  setCheckStatus(wettkampf: Competition): void {
    // Add if not yet in list
    if (!this.selectedCompetitions.find(c => c.id === wettkampf.id)) {
      this.selectedCompetitions = [...this.selectedCompetitions, wettkampf];
      return;
    }

    // Remove from list otherwise
    this.selectedCompetitions = this.selectedCompetitions.filter(
      c => c.id !== wettkampf.id
    );
  }

  getIsDisabled(index: number): boolean {
    return !this.gridData[index].aenderbar;
  }

  rowCallback(context: RowClassArgs): RowClass {
    return {
      provisional: !context.dataItem.aenderbar
    };
  }
}
