import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { KendoDropdownItem } from '@ssmm-shared/data/kendo-dropdown-item.interface';
import { AgeGroupService } from '@ssmm-shared/services/age-group.service';
import { BackendService } from '@ssmm-shared/services/backend.service';
import { HalService } from '@ssmm-shared/services/hal.service';
import { DropDownValidators } from '@ssmm-shared/validators/dropdown-validators';
import { Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { Schueler } from 'ssmm-models';
import { EventEmitter } from '@angular/core';
import { StudentManagementComponent } from 'app/competition-organisation/school-organisation/vl-student-management/student-management.component';
import { ToastNotificationService } from '@ssmm-shared/services/toast-notification.service';

@Component({
  selector: 'ssmm-create-edit-student-dialog',
  templateUrl: './create-edit-student.component.html',
  styleUrls: ['./create-edit-student.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateEditStudentDialogComponent implements OnInit, OnDestroy {
  @Input() createStudentLink: string;
  @Input() editStudentLink: string;
  @Input() title: string;
  @Input() studentToEdit: Schueler;
  @Input() editMode: boolean;
  @Input() isExpressMode: boolean;
  @Input() repeatedStudent: boolean;
  @Output() studentAdded = new EventEmitter<void>();
  @Output() studentEdited = new EventEmitter<void>();
  @Output() closeEditDialog = new EventEmitter<void>();
  @Output() closeWarningDialog = new EventEmitter<void>();
  @Output() createNewForm = new EventEmitter<boolean>();

  @ViewChild('studentManagement') studentManagement: StudentManagementComponent;

  ageGroupValues: KendoDropdownItem[];

  sexValues: KendoDropdownItem[] = [
    { text: 'Junge', value: 'MAENNLICH' },
    { text: 'Mädchen', value: 'WEIBLICH' }
  ];

  opened = false;

  wasSaveRequested: boolean;

  wrapperGroup: UntypedFormGroup;

  readonly formModel = {
    firstName: new UntypedFormControl(null, [
      Validators.required,
      Validators.maxLength(100)
    ]),
    lastName: new UntypedFormControl(null, [
      Validators.required,
      Validators.maxLength(100)
    ]),
    ageGroup: new UntypedFormControl(
      null,
      DropDownValidators.notDefaultItemSelected
    ),
    sex: new UntypedFormControl(
      null,
      DropDownValidators.notDefaultItemSelected
    ),
    termsAndConditions: new UntypedFormControl(false, Validators.requiredTrue)
  };

  readonly dropDownDefaultItem: KendoDropdownItem = {
    text: 'Bitte wählen',
    value: -1
  };

  private readonly _unsubscribe$ = new Subject<void>();

  constructor(
    private _cdr: ChangeDetectorRef,
    private _ags: AgeGroupService,
    private _halService: HalService,
    private _bs: BackendService<null, Schueler>,
    private _toastService: ToastNotificationService
  ) {}

  ngOnInit(): void {
    this.ageGroupValues = this._ags.getAgeGroupYearItems(
      this._halService.getSaisonStartYear()
    );

    this.wrapperGroup = new UntypedFormGroup(this.formModel);
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  onDialogClose(): void {
    this.opened = false;
    this.wrapperGroup.reset();
    if (this.editMode && this.studentToEdit) {
      this.closeEditDialog.emit();
    }
    if (this.repeatedStudent) {
      this.closeWarningDialog.emit();
    }
  }

  toStudentModel(wrapperGroup: UntypedFormGroup): Schueler {
    const gender = wrapperGroup.get('sex').value;
    const year = wrapperGroup.get('ageGroup').value;
    const commonFields = {
      vorname: wrapperGroup.get('firstName').value,
      nachname: wrapperGroup.get('lastName').value,
      geschlecht: gender.value,
      jahrgang: year.value,
      einwilligungDvVorhanden: wrapperGroup.get('termsAndConditions').value
    };

    if (!this.editMode) {
      return {
        ...commonFields,
        validationWarnings: {
          warningJahrgangValidierung: false,
          warningGeschlechtsValidierung: false
        },
        sportartenMitTeilnahmen: []
      };
    } else {
      return {
        ...commonFields,
        validationWarnings: {
          warningJahrgangValidierung:
            this.studentToEdit?.validationWarnings
              ?.warningJahrgangValidierung ?? false,
          warningGeschlechtsValidierung:
            this.studentToEdit?.validationWarnings
              ?.warningGeschlechtsValidierung ?? false
        },
        sportartenMitTeilnahmen:
          this.studentToEdit?.sportartenMitTeilnahmen || []
      };
    }
  }

  onAddStudent(): void {
    this.createStudent(this.wrapperGroup);
  }

  createStudent(wrapperGroup: UntypedFormGroup): void {
    if (wrapperGroup.invalid) {
      this.wasSaveRequested = true;
      wrapperGroup.markAllAsTouched();
      return;
    }

    //This to create
    const student = this.toStudentModel(wrapperGroup);

    this._bs
      .saveItem(this.createStudentLink, student)
      .pipe(
        takeUntil(this._unsubscribe$),
        catchError(() => {
          this.opened = false;
          return [this._toastService.errorGeneric()];
        })
      )
      .subscribe(() => {
        this.studentAdded.emit();
        this.opened = false;
        this._toastService.success('Schüler erfolgreich hinzugefügt');
        wrapperGroup.reset();
        if (this.isExpressMode && !this.repeatedStudent) {
          this.createNewForm.emit(true);
        }
        if (this.repeatedStudent && this.isExpressMode) {
          this.closeWarningDialog.emit();
        }
      });
  }

  onEditStudent(): void {
    this.editStudent(this.wrapperGroup);
  }

  editStudent(wrapperGroup: UntypedFormGroup): void {
    if (wrapperGroup.invalid) {
      this.wasSaveRequested = true;
      wrapperGroup.markAllAsTouched();
      return;
    }

    const studentToEdit = this.toStudentModel(wrapperGroup);

    const student = this.changeValuesFromStudent(studentToEdit);

    // Update with the new values
    this._bs
      .updateItem(this.editStudentLink, student)
      .pipe(
        takeUntil(this._unsubscribe$),
        catchError(() => {
          this.opened = false;
          return [this._toastService.errorGeneric()];
        })
      )
      .subscribe(() => {
        this.studentEdited.emit();
        this.opened = false;
        wrapperGroup.reset();
        this._toastService.success('Schüler erfolgreich bearbeitet');
      });
  }

  open(): void {
    this.opened = true;
    this.enableWrapperGroup();
    //If we have a student to edit, we need to fill the form with the student data
    if (this.editMode) {
      if (this.studentToEdit) {
        this.fillStudentsInfo();
      } else {
        this.opened = false;
      }
    }
    this._cdr.detectChanges();
  }

  fillStudentsInfo(): void {
    this.wrapperGroup.patchValue({
      firstName: this.studentToEdit.vorname,
      lastName: this.studentToEdit.nachname,
      ageGroup: this.ageGroupValues.find(
        x => x.value === this.studentToEdit.jahrgang
      ),
      sex: this.sexValues.find(v => v.value === this.studentToEdit.geschlecht),
      termsAndConditions: this.studentToEdit.einwilligungDvVorhanden
    });

    if (this.repeatedStudent) {
      this.wrapperGroup.get('firstName').disable();
      this.wrapperGroup.get('lastName').disable();
      this.wrapperGroup.get('ageGroup').disable();
      this.wrapperGroup.get('sex').disable();
      this.wrapperGroup.get('termsAndConditions').disable();
    } else {
      this.enableWrapperGroup();
    }
    this._cdr.detectChanges();
  }

  changeValuesFromStudent(student: Schueler): Schueler {
    this.studentToEdit.vorname = student.vorname;
    this.studentToEdit.nachname = student.nachname;
    this.studentToEdit.geschlecht = student.geschlecht;
    this.studentToEdit.jahrgang = student.jahrgang;
    this.studentToEdit.einwilligungDvVorhanden =
      student.einwilligungDvVorhanden;

    return this.studentToEdit;
  }

  enableWrapperGroup(): void {
    this.wrapperGroup.get('firstName').enable();
    this.wrapperGroup.get('lastName').enable();
    this.wrapperGroup.get('ageGroup').enable();
    this.wrapperGroup.get('sex').enable();
    this.wrapperGroup.get('termsAndConditions').enable();
  }
}
